import React from 'react'
import './Banner.css'
// import Countdown from "react-countdown";
import DefiAnimOrig from '../../Assets/images/logo.gif'
import DefiAnimPlace from '../../Assets/images/logo.gif'
import logIcon from '../../Assets/images/logo-icon.webp'
import circleFrame from '../../Assets/images/circleFrame.gif'
import copyIcon from '../../Assets/images/copyicon.svg'
import bannerimage from '../../Assets/images/v1.mp4'
import ProgressiveImage from "react-progressive-graceful-image";
import { FaTelegramPlane, FaRegEnvelope, FaTwitter, FaMediumM, FaInstagram, FaRedditAlien, FaFacebookF, FaYoutube } from "react-icons/fa";
// import Countdown from "react-countdown";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-scroll';
import ReactPlayer from "react-player";
// import nftmain from '../../Assets/images/nft.mp4'
// import ReactPlayer from 'react-player'
// import LazyLoad from 'react-lazyload';
// import Lottie from 'react-lottie';
const notify = () => toast.success('Copied Successfully!')
const Banner = () => {
    return (
        <>
            <section className="ox_banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-sm-12">
                            <div className="infobox">
                                <h1>Empowering Digital Ownership</h1>
                                <p className="">DEFI World, a forward-thinking blockchain company dedicated to pioneering cutting-edge decentralized applications, is thrilled to unveil its groundbreaking NFT Staking Platform, designed to operate seamlessly on the Polygon Chain.</p>
                                <p className="mb-5">Our platform empowers users to maximize their NFT holdings by participating in a secure and decentralized staking process, offering attractive rewards.</p>


                                {/* <div className='mt-3 smartContractBx'>
                                    <label>DEFI Smart Contract :</label>
                                    <div>
                                        <a href="https://polygonscan.com/token/0x6032e93ddc9ef65212a9bde047a93e5d16126c8e" target="_blank" rel="noreferrer">0x60....6c8e</a>
                                        <CopyToClipboard text="https://polygonscan.com/token/0x6032e93ddc9ef65212a9bde047a93e5d16126c8e">
                                            <img onClick={notify} src={copyIcon} alt="Copy Icon" />
                                        </CopyToClipboard>
                                    </div>
                                </div> */}
                                {/* <h2 className='quickPlayTitle'>Quick Links</h2> */}
                                <div className="buttonGrp headGrpBtn">
                                    <a href='https://defiworld.ai'   to="comingsoonSrl" target='_blank' rel="noreferrer" className="defi_btn" smooth={true} duration={0}> Affiliate</a>
                                </div>
                                {/* <div className="buttonGrp headGrpBtn">
                                    <a href="#!" target="_blank" rel="noreferrer" className="defi_btn">DWC Stake</a>
                                    <a href="#!" target="_blank" rel="noreferrer" className="defi_btn">ICO Sale</a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12  order-first  order-md-last mb-5">

                            <div className="imageBox">
                                <ReactPlayer
                                    className="color-changing-element"
                                    // src={banner}
                                    // height={480}
                                    // width={850}
                                    // className="react-player nftvideo "
                                    url={bannerimage}
                                    width="500"
                                    height="600"
                                    muted
                                    loop
                                    playing="true"
                                    playsinline="true"
                                />
                                {/* <img src={bannerimage} alt="" /> */}


                                {/* <ProgressiveImage delay={0} src={DefiAnimOrig} placeholder={DefiAnimPlace}>
                                    {(src) => <img className='mainImg' src={src} alt="" />}
                                </ProgressiveImage> */}
                                {/* <svg
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 1000 1000"
                                    style={{ enableBackground: 'new 0 0 1000 1000' }}
                                    xmlSpace="preserve"
                                >
                                    <circle className="st0" cx="500" cy="500" r="302.8">
                                        <animateTransform
                                            attributeType="xml"
                                            attributeName="transform"
                                            type="rotate"
                                            from="0 500 500"
                                            to="360 500 500"
                                            dur="50s"
                                            repeatCount="indefinite"
                                        />
                                    </circle>
                                    <circle className="st1" cx="500" cy="500" r="237.7">
                                        <animateTransform
                                            attributeType="xml"
                                            attributeName="transform"
                                            type="rotate"
                                            from="0 500 500"
                                            to="360 500 500"
                                            dur="10s"
                                            repeatCount="indefinite"
                                        />
                                    </circle>
                                    <circle
                                        className="st2"
                                        cx="500"
                                        cy="500"
                                        r="366.8"
                                        transform="rotate(0 500 500)"
                                    >
                                        <animateTransform
                                            attributeType="xml"
                                            attributeName="transform"
                                            type="rotate"
                                            from="0 500 500"
                                            to="-360 500 500"
                                            dur="20s"
                                            repeatCount="indefinite"
                                        />
                                    </circle>
                                    <circle className="st3" cx="500" cy="500" r="385.1" />
                                </svg> */}
                                {/* <img src={logIcon} alt="logIcon" className='defiWorldIcon' /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container buttonMainbanner">
                    <div className="textgames">
                        <h3>  DeFi World Ecosystem  </h3>
                    </div>
                    <div className="row flex-wrap align-baseline justify-content-center">
                        <div className='col-6 col-sm-6 col-md-3 mb-4 md-mb-2'>
                            <div className="buttonGrp headGrpBtn">
                                <a href="https://opensea.io/assets/matic/0xb5f8ce861bcc32475bc811be9a7fb2ac4249b0f6/1" to="comingsoonSrl" rel="noreferrer" target='_blank' className="defi_btn" smooth={true} duration={0}> Buy NFT</a>
                            </div>
                            {/* <a  target="_blank" rel="noreferrer"> */}
                            {/* <img src={circleFrame} alt="Swap" />
                                <span>Swap</span> */}
                            {/* </a> */}
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4 md-mb-2'>

                            <div className="buttonGrp headGrpBtn">
                                <a href='https://nft.defiworld.finance/'  target='_blank'  to="comingsoonSrl" rel="noreferrer" className="defi_btn" smooth={true} duration={0}> Stake NFT </a>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4 md-mb-2'>
                            <div className="buttonGrp headGrpBtn">
                                <a href='https://stake.defiworld.finance/' target='_blank' to="comingsoonSrl" rel="noreferrer" className="defi_btn" smooth={true} duration={0}> Token Stake </a>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4 md-mb-2'>
                            <div className="buttonGrp headGrpBtn">
                                <a href='https://swap.defiworld.finance/' target='_blank' to="comingsoonSrl" rel="noreferrer" className="defi_btn" smooth={true} duration={0}> DeFi Swap</a>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4 md-mb-2'>

                            <div className="buttonGrp headGrpBtn">
                                <a href='https://sale.defiworld.finance/'  target='_blank' to="comingsoonSrl" rel="noreferrer" className="defi_btn" smooth={true} duration={0}> ICO Sale</a>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4 md-mb-2'>

                            <div className="buttonGrp headGrpBtn">
                                <a href='https://game.defiworld.finance/' target='_blank' to="comingsoonSrl" rel="noreferrer" className="defi_btn" smooth={true} duration={0}>Games</a>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4 md-mb-2'>

                            <div className="buttonGrp headGrpBtn">
                                {/* <a href='https://dex.defiworld.finance/'  target='_blank' to="comingsoonSrl" rel="noreferrer" className="defi_btn" smooth={true} duration={0}> AI Dex</a> */}
                                <a href='https://dexdefiworld.com/'  target='_blank' to="comingsoonSrl" rel="noreferrer" className="defi_btn" smooth={true} duration={0}> AI Dex</a>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4 md-mb-2'>
                            <div className="buttonGrp headGrpBtn">
                                <a href='https://blockchain.defiworld.finance/' target='_blank' to="comingsoonSrl" rel="noreferrer" className="defi_btn" smooth={true} duration={0}> Blockchain
                                </a>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4 md-mb-2'>
                            <div className="buttonGrp headGrpBtn">
                                <a href='https://shop.defiworld.finance/' target='_blank' to="comingsoonSrl" rel="noreferrer" className="defi_btn" smooth={true} duration={0}>
                                     Shop
                                </a>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-4 md-mb-2'>
                            <div className="buttonGrp headGrpBtn">
                                <a href='https://cabinet.thewealthfx.com/client/register/665dc0477666e' target='_blank' to="comingsoonSrl" rel="noreferrer" className="defi_btn" smooth={true} duration={0}> Forex
                                </a>
                            </div>
                        </div>

                        {/* <div className='col-6 col-sm-6 col-md-3 mb-4 md-mb-2'>
                         
                            <div className="buttonGrp headGrpBtn">
                                    <Link to="comingsoonSrl" rel="noreferrer" className="defi_btn" smooth={true} duration={0}> ICO Sale</Link>
                                </div>
                        </div> */}


                    </div>
                </div>
                {/* <div className='container'>
                    <LazyLoad className="logoMainHead" once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <Lottie
                            options={nftmain}
                            width="160px"
                            height="160px"
                        />
                        <ReactPlayer
                            url={nftmain}
                            width='100%'
                            height='100%'
                            muted
                            loop
                            playing={true}
                            playsinline={true}
                            autoPlay={true}
                        />
                    </LazyLoad>
                </div> */}
            </section>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
        </>
    )
}

export default Banner